import React, { FC, useState } from 'react';
import { CheckValidationContext } from './CheckValidationContext';

const CheckValidationContextProvider: FC = ({ children }) => {
  const [checkValidation, setICheckValidation] = useState(false);

  const handleSetPrescriber = (validate: boolean) => {
    setICheckValidation(validate);
  };
  return (
    <CheckValidationContext.Provider
      value={{ checkValidation, setICheckValidation: handleSetPrescriber }}
    >
      {children}
    </CheckValidationContext.Provider>
  );
};

export default CheckValidationContextProvider;
