import React, { FC, useState } from 'react';
import { RouteContext } from './RouteContext';

const RouteContextProvider: FC = ({ children }) => {
  const [isChild, setIsChild] = useState(false);

  const handleSetRoute = (childState: boolean) => {
    setIsChild(childState);
  };
  return (
    <RouteContext.Provider value={{ isChild, setIsChild: handleSetRoute }}>
      {children}
    </RouteContext.Provider>
  );
};

export default RouteContextProvider;
