import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles((theme: Theme) => ({
  textContainer: {
    marginBottom: '3em',
    marginTop: '2em',
    fontSize: '1rem',
  },
  articleTitle: {
    fontWeight: 'bold',
  },
  title: {
    fontFamily: 'PT Serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 34,
    textAlign: 'center',
  },
  textPadding: {
    paddingTop: '2em',
  },
  ulsStyle: {
    listStyleType: '"-   "',
  },
  textCenter: {
    textAlign: 'center',
  },
}));
