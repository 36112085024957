import React from 'react';

export interface IShowCrispContext {
  showCrisp: boolean;
  setShowCrisp: (show: boolean) => void;
}

export const ShowCrispContext = React.createContext<Partial<IShowCrispContext>>({});

export const useShowCrispContext = () => React.useContext(ShowCrispContext);
