import gql from 'graphql-tag';

export const IS_AUTH = gql`
  {
    testToken {
      success
    }
  }
`;

export const QUERY_GET_USER_By_MAIL = gql`
  query getSwitchUserByMail($mail: String!) {
    getSwitchUserByMail(mail: $mail) {
      email
      phoneNumber
      id
      firstname
      lastname
      uniqId
      honorificPrefix
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query getUserById($userId: String!, $isAdmin: Boolean) {
    getUserById(userId: $userId, isAdmin: $isAdmin) {
      phoneNumber
      email
      firstname
      lastname
      id
      countActiveOrganizations
      uniqId
      honorificPrefix
    }
  }
`;
