import React from 'react';

export interface RefetchContextInterface {
  refetch: boolean;
  setRefetch: (refetch: boolean) => void;
}

export const RefetchContext = React.createContext<Partial<RefetchContextInterface>>({});

export const useRefetchContext = () => React.useContext(RefetchContext);
