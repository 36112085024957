import React from 'react';

export interface ICheckValidationContext {
  checkValidation: boolean;
  setICheckValidation: (validate: boolean) => void;
}

export const CheckValidationContext = React.createContext<Partial<ICheckValidationContext>>({});

export const useCheckValidationContext = () => React.useContext(CheckValidationContext);
