import React, { FC, useState } from 'react';
import { ShowZooContext } from './ShowZooContext';

const ShowZooContextProvider: FC = ({ children }) => {
  const [showZoo, setShowZoo] = useState(false);

  const handleShowZoo = (show: boolean) => {
    setShowZoo(show);
  };
  return (
    <ShowZooContext.Provider value={{ showZoo, setShowZoo: handleShowZoo }}>
      {children}
    </ShowZooContext.Provider>
  );
};

export default ShowZooContextProvider;
