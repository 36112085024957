import { OrganizationStatusEnum, StatusInvoicePayed_Type } from './OrganizationInterface';

export interface Category {
  option: CategoryOptionEnum;
  isLetterImagePaid: boolean;
  isOpenMenu: boolean;
  secondOption: CategorySecondOptionEnum;
}

export enum ERoutePrincipal {
  DOMICILIATION = 'domiciliation',
  IMMATRICULATION = 'creation-entreprise',
  PRESCRIBER = 'prescriber',
  TUNNEL = 'tunnel',
  DIGIDOM_CGV = 'digidom-cgv',
}

export enum ERouteTunnel {
  ADDRESS_CHOICE = 'choix-adresse',
  FIND_OFFICE = 'trouver-bureau',
  OFFER_SUMMARY = 'recapitulatif',
  LEGAL_INFORMATION_CHOICE = 'legal-information',
  LEGAL_NAME = 'nom-organisation',
  TRANSFERT_OFFICE = 'transfert-siege',
  INFO_COMPTE = 'info-compte',
  LETTER_CHOICE = 'choix-courrier',
  KNOWING_DIGIDOM = 'connaissance-de-digidom',
  DOM_OPTION = 'dom-option',
  STAGE_PROJECT = 'immat-option',
  DOM_LEGAL_INFO = 'information-legale',
  MY_CONTRACT = 'contrat',
  ORGANIZATION_INFO = 'information-societe',
  MY_IDENTITY = 'rl-1',
  REGISTERED_ADDRESS = 'adresse-siege',
  MY_ORGANIZATION = 'rl-2',
  DOM_REGISTER_OFFICE = 'siege-social',
  OBJECT_EXERCISE_SOCIAL = 'exercice-social',
  SHARE_CAPITAL = 'capital-social',
  SHARE_CAPITAL_DIVISION = 'repartition-des-parts',
  INFOS_AUDITOR = 'commissaire-compte',
  INFO_ASSOCIATE = 'associe',
  MICRO_INFO_ORG = 'micro-a-propos',
  TAX_OPTION = 'option-fiscale',
  MICRO_INFO_ASSOCIATE = 'micro-information',
  DATE_CONTRACT = 'date-d-effet',
  ACCOUNTING = 'comptabilite',
  INFO_LEGAL_AFTER_PAY = 'information-legale-apres-paiement',
  SUBSCRIPTION_SUMMARY = 'recapitulatif-offre',
  MY_DOCUMENT = 'documents',
  CROSS_SELLING = 'cross-selling',
  PAYMENT = 'paiement',
  BASE = '',
  CONFIRM_IMMAT = 'confirmation',
  CONFIRM_DOM = 'confirmation-dom',
  CONTINUE = 'continue',
  CONFIRM_IMMAT_PAYMENT = 'confirmation-paiement',
  CONFIRM_IMMAT_GENERATE = 'confirmation-generation-contrat',
  BANK = 'banque',
  FORWARD_ADDRESS = 'adresse-reexpedition',
  FINISHED = 'finishing-tunnel',
  QONTO = 'qonto',
  ADDRESS_IMMAT = 'adresse-immat',
  RINGOVER = 'ringover',
  ACTIVITY_DOMAIN = 'domaine-activite',
  COMPTABILITY = 'comptabilite',
  IMMAT_PACK = 'pack-immat',
}

export enum ERouteOption {
  LETTER = 'letter',
  DOCUMENT = 'document',
  ACCOUNT = 'account',
  NEW_ORGANIZATION = 'organization',
  CUSTOMER_SUMMARY = 'customer-summary',
  LEGAL_SUBSCRIPTION = 'legal-subscription',
  DIGIDOM_ACADEMY = 'digidom-academy',
  ROOM_RENTAL = 'location-salle',
}

export enum ERouteOptionNewOrganization {
  NEW = 'new',
  NO_PACK = 'no-pack',
  NO_PACK_MICRO = 'no-pack-micro',
  CHOICE = 'choice',
}

export enum ERouteImmatriculation {
  DERNIERE_ETAPE = 'derniere-etape',
}

export const categoryName = {
  letter: 'Boîte aux lettres',
  document: 'Mes documents',
  account: 'Mon compte',
  organization: 'Nouvelle entreprise',
  'customer-summary': 'Mes factures',
  'legal-subscription': 'La mallette juridique',
  'digidom-academy': 'Digidom Academy',
  'location-salle': 'Location de salle',
};

export enum ERouteSecondOption {
  INFO = 'info',
  MANDATE = 'mandate',
  FORWARD = 'forward-address',
  AUTH_POSTAL_ADDRESS = 'postal-auth',
  MISSING_DOC = 'missing-doc',
  SETTING = 'setting',
  CLOUD = 'cloud',
  ORG_PARAM = 'setting-org',
  INVOICE = 'invoice',
  VALID_DOC = 'valid-doc',
  WAITING_DOC = 'waiting-doc',
  ACCOUNT_DEPOSIT = 'depot-de-compte',
}

export enum ERouteMyActions {
  HEADQUARTER = 'headquarter',
  LEADER = 'leader',
  SHARES = 'shares',
  PV_DEED = 'PV-deed',
  LIQUIDATION = 'liquidation',
  CHANGE_FISCAL = 'change-fiscal',
  LEGAL_FORM = 'legal-form',
  SHARE_CAPITAL = 'share-capital',
  AUDITOR = 'auditor',
  ACTIVITY = 'activity',
  OTHER_FORMALITY = 'other-formality',
}

export const profilDetails = {
  info: 'Mes infos',
  invoice: 'Mes factures Digidom',
  mandate: 'Mandater une personne',
  'forward-address': 'Mon adresse de réexpédition',
  'postal-auth': 'Procuration postale',
  'valid-doc': 'Documents validés',
  'missing-doc': 'Documents manquants',
  setting: 'Réglage',
  cloud: 'Ajouter un cloud',
  'setting-org': 'Mes organismes',
  'waiting-doc': 'Documents validation en cours',
  'depot-de-compte': 'Dépôt de compte',
};

export type CategoryOptionEnum =
  | 'LETTER_BOX'
  | 'MY_DOCUMENTS'
  | 'MY_ACTIONS'
  | 'MY_ACCOUNT'
  | 'NEW_ORGANIZATION';

export type CategorySecondOptionEnum =
  | undefined
  | 'INFO'
  | 'INVOICE'
  | 'MANDATE'
  | 'ADDRESS'
  | 'AUTH'
  | 'VALID_DOC'
  | 'MISSING_DOC'
  | 'SETTING_PARAM'
  | 'CLOUD_PARAM'
  | 'ORG_PARAM';

export const accountOptions = {
  OFFER: 'Mon offre',
  PROFIL: 'Mon profil',
  DOC: 'Documents requis',
  PARAM: 'Paramètres',
};

export interface SelectedOrganizationInterface {
  id: string;
  legalName: string;
  missingDocuments: number;
  status: OrganizationStatusEnum;
  terminationReason: string;
  terminationDate: string;
  invoiceToken: string;
  statusInvoicePayed: StatusInvoicePayed_Type;
}
