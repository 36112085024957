import { useMutation, useQuery } from '@apollo/react-hooks';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';
import React, { FC } from 'react';
import { SNACKBAR } from '../../../graphql/local/snackBar/mutation';
import { GET_SNACKBAR_STATE } from '../../../graphql/local/snackBar/query';
import SnackVariableInterface from '../../../Interface/SnackVariableInterface';

const GlobalSnackBar: FC = () => {
  const [mutationSnackBar] = useMutation(SNACKBAR);

  const { data } = useQuery<{ snackBar: SnackVariableInterface }>(GET_SNACKBAR_STATE);

  if (!data || !data.snackBar) {
    return null;
  }
  const snackBarData: SnackVariableInterface = data.snackBar;
  const { message, type, isOpen, duration } = snackBarData;

  const handleClose = () => {
    mutationSnackBar({
      variables: {
        ...snackBarData,
        isOpen: false,
      },
    });
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isOpen}
      autoHideDuration={duration!}
      onClose={() => handleClose()}
      action={[
        <IconButton key="close" aria-label="Close" color="primary" onClick={() => handleClose()}>
          <CloseIcon />
        </IconButton>,
      ]}
    >
      <SnackbarContent
        onClick={() => handleClose()}
        message={message}
        style={{
          backgroundColor: type === 'SUCCESS' ? '#2bc93a' : 'red',
          fontSize: 14,
          zIndex: 9,
        }}
      />
    </Snackbar>
  );
};

export default GlobalSnackBar;
