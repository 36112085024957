import { useMemo } from 'react';
import AuthService from './AuthService';

export default () => {
  const switchUser = AuthService.getSwitchUser();
  const switchUserId = AuthService.getSwitchUserId();
  if (switchUser && switchUserId) {
    return true;
  }
  return false;
};

export const useIsAdmin = () => {
  return useMemo(() => {
    const switchUser = AuthService.getSwitchUser();
    const switchUserId = AuthService.getSwitchUserId();
    if (switchUser && switchUserId) {
      return true;
    }
    return false;
  }, []);
};
