import AuthService from './AuthService';

export interface IRefreshToken {
  token?: string;
  tokenDuration?: number;
  tokenExpirationDate?: Date;
  refreshToken?: string;
  username?: string;
}

export class RefreshToken {
  static getTRefreshToken = () => {
    const refreshTokenString = AuthService.getRefreshToken();
    let parsed: IRefreshToken = {};
    if (refreshTokenString) {
      parsed = JSON.parse(refreshTokenString);
      const defaultDate = new Date();
      if (parsed.tokenDuration) {
        defaultDate.setSeconds(defaultDate.getSeconds() + parsed.tokenDuration);
      }

      parsed.tokenExpirationDate = parsed.tokenExpirationDate
        ? new Date(parsed.tokenExpirationDate)
        : defaultDate;
    }
    return parsed;
  };

  static setRefreshToken = (data: IRefreshToken) => {
    AuthService.setRefreshToken(JSON.stringify(data));
  };

  static removeRefreshToken = () => {
    AuthService.removRefreshToken();
  };
}
