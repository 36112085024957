import React from 'react';

export interface IRouteContext {
  isChild: boolean;
  setIsChild: (isChild: boolean) => void;
}

export const RouteContext = React.createContext<Partial<IRouteContext>>({});

export const useRouteContext = () => React.useContext(RouteContext);
