import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient, { ApolloError, DefaultOptions } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-link-http';
import { withClientState } from 'apollo-link-state';
import defaults from '../apollo/Defaults';
import resolvers from '../apollo/Resolvers';
import typeDefs from '../apollo/typeDef';
import { REFRESH_TOKEN } from '../graphql/Authentication/mutation';
import { UserInterface } from '../Interface/UserInterface';
import AuthService from '../Services/AuthService';
import { RefreshToken } from '../Services/RefreshToken';
import config from './config';
import { onError } from 'apollo-link-error';

let maxRetry = 0;

const logoutLink: ApolloLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors && graphQLErrors.length > 0) {
    const error = graphQLErrors[0];
    if (
      ((error.extensions && error.extensions.code === 'UNAUTHENTICATED') ||
        (error.message && /AuthenticationError/gi.test(error.message.toString()))) &&
      maxRetry <= 5
    ) {
      maxRetry++;
      const { refreshToken } = RefreshToken.getTRefreshToken();
      if (refreshToken) {
        getRefreshedAccessTokenPromise()
          .then(() => {
            window.location.reload();
          })
          .catch(() => {
            if (!window.location.pathname.includes('logout')) {
              window.location.href = `${config.myURI}/auth/logout`;
            }
          });
      } else {
      }
    } else {
      // if (!window.location.pathname.includes('logout')) {
      //   window.location.href = `${config.myURI}/auth/logout`;
      // }
    }
  }
}) as ApolloLink;

const authLink = setContext((_: any, { headers }: { headers: any }) => {
  const token = AuthService.getAccessToken();
  const switchUser = AuthService.getSwitchUser();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      switchuser: switchUser ? switchUser : '',
    },
  };
});

const stateLink = withClientState({ defaults, resolvers, typeDefs });

const httpLink = new HttpLink({
  uri: config.serverUri,
});

const cache: InMemoryCache & { readQueryClone?: any } = new InMemoryCache();
cache.readQueryClone = cache.readQuery;

cache.readQuery = (...args) => {
  try {
    return cache.readQueryClone(...args);
  } catch (err) {
    return undefined;
  }
};

const link = ApolloLink.from([logoutLink, stateLink, authLink.concat(httpLink)]);

const defaultOptions: DefaultOptions = {
  mutate: {
    errorPolicy: 'all',
  },
};

const apolloClient = new ApolloClient({
  defaultOptions,
  link,
  cache,
});

const getRefreshedAccessTokenPromise = async () => {
  try {
    const actualToken = AuthService.getAccessToken();
    const { refreshToken, username } = RefreshToken.getTRefreshToken();
    if (refreshToken && username) {
      const { data, errors } = await apolloClient.mutate<
        {
          refreshToken: {
            refreshToken: string;
            token: string;
            tokenDuration: number;
            tokenExpirationDate: Date;
            user: UserInterface;
          };
        },
        { refreshToken: string; username: string }
      >({
        mutation: REFRESH_TOKEN,
        variables: {
          refreshToken,
          username,
        },
      });

      if (data && data.refreshToken) {
        const { token, refreshToken, tokenDuration, tokenExpirationDate, user } = data.refreshToken;
        if (token) {
          AuthService.setAccessToken(token);
          AuthService.setUserId(user.id);
        }

        RefreshToken.setRefreshToken({
          refreshToken,
          token,
          tokenDuration,
          tokenExpirationDate,
          username: (user && user.email) || '',
        });
        return data.refreshToken.token;
      }

      if (errors) {
        throw new Error(errors[0].message);
      }

      return actualToken;
    }

    throw new Error('No refresh token');
  } catch (error) {
    throw new ApolloError(error as any);
  }
};

export default apolloClient;
