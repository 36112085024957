import { StoreDataService } from '../../Services/StoreDataTunnel';
import { defauldData } from '../defaultValues';

const data = StoreDataService.getDataTunnel();

export default {
  legalInformation: {
    legalName: null,
    legalStatus: 'EURL',
    id: null,
    __typename: 'LegalInformation',
  },
  myIdentity: {
    type: 'PHYSICAL',
    __typename: 'MyIdentity',
  },
  myOrganization: {
    isLegalRepresentative2: false,
    type: null,
    __typename: 'MyOrganization',
  },
  tunnelData: {
    ...defauldData,
    ...data,
    __typename: 'TunnelData',
  },
};
