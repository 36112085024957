import gql from 'graphql-tag';

export const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      tokenDuration
      tokenExpirationDate
      refreshToken
      user {
        email
        phoneNumber
        id
        lastname
        firstname
        countActiveOrganizations
        mainPack
        uniqId
      }
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshToken($refreshToken: String!, $username: String!) {
    refreshToken(refreshToken: $refreshToken, username: $username) {
      token
      tokenDuration
      tokenExpirationDate
      refreshToken
      user {
        email
        phoneNumber
        id
        lastname
        firstname
        countActiveOrganizations
        mainPack
        uniqId
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($username: String!) {
    forgotPassword(username: $username) {
      success
      message
    }
  }
`;

export const GET_USER_PHONE_NUMBER = gql`
  mutation GET_USER_PHONE_NUMBER($phoneNumber: String!) {
    getUserByPhoneNumber(phoneNumber: $phoneNumber) {
      email
      phoneNumber
      id
      lastname
      firstname
      countActiveOrganizations
      mainPack
    }
  }
`;
export const GET_USER_By_MAIL = gql`
  mutation GET_USER_By_MAIL($mail: String!) {
    getUserByMail(mail: $mail) {
      email
      phoneNumber
      id
      lastname
      firstname
      countActiveOrganizations
      mainPack
    }
  }
`;

export const FIND_USER_BY_MAIL = gql`
  mutation FIND_USER_BY_MAIL($mail: String!) {
    findUserByMail(mail: $mail) {
      id
      uniqId
      success
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($userId: Int!, $data: UserInput) {
    updateUser(userId: $userId, data: $data) {
      id
      email
      phoneNumber
      lastname
      firstname
    }
  }
`;
