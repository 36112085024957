import { LegalStatusEnum } from './../Interface/Tunnel/LegalInformation';
import {
  BankChoiceEnum,
  HeadQuartersAddressTypeEnum,
  ProjectStageEnum,
} from './../Interface/Dashboard/OrganizationInterface';
import AuthService from './AuthService';

export interface ITunnelData {
  creationtype?: string;
  addressId?: string;
  addressIdFromUrl?: string;
  letterType?: string;
  packImmatName?: string;
  packImmatNameFromUrl?: string;
  packDomNameFromUrl?: string;
  packDomName?: string;
  legalStatus?: LegalStatusEnum | null;
  legalStatusFromUrl?: string;
  enter?: string;
  historyNavTunnel?: string;
  organization?: string;
  parentId?: string;
  projectStage?: ProjectStageEnum | null;
  headQuartersAddressType?: HeadQuartersAddressTypeEnum | null;
  haveAccountant?: boolean | null;
  bankChoice?: BankChoiceEnum | null;
  bankName?: string | null;
  activityDomain?: string | null;
  isDigidomAccountant?: boolean | null;
  isCrossSelling?: boolean | null;
}

export class StoreDataService {
  static getDataTunnel = () => {
    const tunnelDataString = AuthService.getTunnelData();
    let parsed: ITunnelData = {};
    if (tunnelDataString) {
      parsed = JSON.parse(tunnelDataString);
    }
    return parsed;
  };

  static setDataTunnel = (data: ITunnelData) => {
    AuthService.setTunnelData(JSON.stringify(data));
  };

  static removeDataTunnel = () => {
    AuthService.removTunnelData();
  };
}
