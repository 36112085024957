import React, { FC, useState } from 'react';
import { ShowCrispContext } from './ShowCrispContext';

const ShowCrispContextProvider: FC = ({ children }) => {
  const [showCrisp, setShowCrisp] = useState(true);

  const handleShowCrisp = (show: boolean) => {
    setShowCrisp(show);
  };
  return (
    <ShowCrispContext.Provider value={{ showCrisp, setShowCrisp: handleShowCrisp }}>
      {children}
    </ShowCrispContext.Provider>
  );
};

export default ShowCrispContextProvider;
