import React, { FC, useState } from 'react';
import { IDataIsInRiskCountry, IsInRiskCountryContext } from './IsInRiskCountryContext';

const IsInRiskCountryContextProvider: FC = ({ children }) => {
  const [isInRiskCountryList, setIsInRiskCountryList] = useState<IDataIsInRiskCountry[]>([]);
  const [loadingCheckIsInRiskCountry, setLoadingCheckIsInRiskCountry] = useState(false);

  const handleSetSIsInRiskCountry = (data: IDataIsInRiskCountry) => {
    let countryList = isInRiskCountryList;
    const index = countryList.findIndex(item => item.id === data.id);

    if (index !== -1) {
      countryList[index] = data;
    } else {
      countryList = [...countryList, data];
    }

    setIsInRiskCountryList(countryList);
  };

  const handleSetLoadingCheckIsInRiskCountry = (loading: boolean) => {
    setLoadingCheckIsInRiskCountry(loading);
  };

  const checkIfHaveIsInRiskCountry = () => {
    return isInRiskCountryList.some(item => item.value);
  };

  const setDefaultIsInRiskCountryList = () => {
    setIsInRiskCountryList([]);
  };

  return (
    <IsInRiskCountryContext.Provider
      value={{
        isInRiskCountryList,
        loadingCheckIsInRiskCountry,
        setLoadingIsInRiskCountryList: handleSetLoadingCheckIsInRiskCountry,
        setIsInRiskCountryList: handleSetSIsInRiskCountry,
        checkIfHaveIsInRiskCountry,
        setDefaultIsInRiskCountryList,
      }}
    >
      {children}
    </IsInRiskCountryContext.Provider>
  );
};

export default IsInRiskCountryContextProvider;
