import React from 'react';

export interface IDataIsInRiskCountry {
  id: string;
  value: boolean;
}

export interface IIsInRiskCountryContext {
  loadingCheckIsInRiskCountry: boolean;
  isInRiskCountryList: IDataIsInRiskCountry[];
  setLoadingIsInRiskCountryList: (loading: boolean) => void;
  setIsInRiskCountryList: (data: IDataIsInRiskCountry) => void;
  checkIfHaveIsInRiskCountry: () => boolean;
  setDefaultIsInRiskCountryList: () => void;
}

const defaultValue: IIsInRiskCountryContext = {
  loadingCheckIsInRiskCountry: false,
  isInRiskCountryList: [],
  setLoadingIsInRiskCountryList: () => {},
  setIsInRiskCountryList: () => {},
  checkIfHaveIsInRiskCountry: () => false,
  setDefaultIsInRiskCountryList: () => {},
};

export const IsInRiskCountryContext = React.createContext<IIsInRiskCountryContext>(defaultValue);

export const useIsInRiskCountryContext = () => React.useContext(IsInRiskCountryContext);
