import parse from 'url-parse';
import config from '../../config/config';
import { getUtmParam } from './utils';

const isInstalled = () => (window.analytics ? true : false);

declare global {
  interface Window {
    analytics: {
      load: (key: string | undefined, arg1: { integrations: any }) => void;
      page: (category?: string, name?: string) => void;
      track: (name: string, properties: any, integration: any) => any;
      trackLink: (element: any, name: string, properties: any) => void;
      timeout: (ms: number) => void;
      identify: (userId: string | null, userData: any) => void;
      user: () => { anonymousId: () => string; id: (value: string | null) => string };
      ready: (user: any) => any;
    };
    dataLayer: any[];
    ga4Datalayer: any[];
  }
}

export const getAnonymousId = () => {
  try {
    return window.analytics.user().anonymousId();
  } catch (error) {
    return undefined;
  }
};

export const getUtms = () => {
  const referrer = window.location.href;
  const url = parse(referrer, true);
  return {
    utm_campaign: getUtmParam('utm_campaign', url),
    utm_content: getUtmParam('utm_content', url),
    utm_medium: getUtmParam('utm_medium', url),
    utm_source: getUtmParam('utm_source', url),
    utm_term: getUtmParam('utm_term', url),
  };
};

export const load = () => {
  if (isInstalled()) {
    window.analytics.load(config.segmentWriteKey, {
      integrations: {
        All: true,
      },
    });
    // window.analytics.page();
    // window.analytics.debug()
  }
};

export const page = (category?: string, name?: string) => {
  if (isInstalled()) {
    window.analytics.page(category, name);
  }
};

export const identify = (userId: string | null, userData: any) => {
  if (isInstalled()) {
    window.analytics.identify(userId, userData);
  }
};

export const track = async (name: string, properties: any, integration?: any) => {
  if (isInstalled()) {
    return window.analytics.track(name, properties, integration);
  }
};

export const ga4track = (name: string, properties: any) => {
  if (window.ga4Datalayer) {
    window.ga4Datalayer.push({
      event: name,
      ...properties,
    });
  }
};

export const setAnalyticsUserId = (userId: string | null) => {
  if (isInstalled() && window.analytics.user) {
    window.analytics.user().id(userId);
  }
};

export const trackLink = (el: any, name: string, properties: any) => {
  if (isInstalled()) {
    window.analytics.trackLink(el, name, properties);
  }
};

// extend timeout
export const timeout = (time: number) => {
  if (isInstalled()) {
    window.analytics.timeout(300);
  }
};
