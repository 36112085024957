import { UserInterface } from '../Interface/UserInterface';
import AuthService from '../Services/AuthService';

export class User {
  static user: UserInterface;

  constructor(_user: UserInterface) {
    User.user = _user;
  }

  static getUser = () => User.user;
  static getSwitchUser = () => {
    const switchUser = AuthService.getSwitchUser();
    return switchUser;
  };
}
