import React, { FC } from 'react';
import useStyle from './style';
import CGVContent from '../CGVContent';
import { Card, CardContent } from '@material-ui/core';

const DigidomCgv: FC = () => {
  const classes = useStyle();

  return (
    <div className={classes.container}>
      <Card className={classes.cardStyle}>
        <CardContent>
          <CGVContent />
        </CardContent>
      </Card>
    </div>
  );
};

export default DigidomCgv;
