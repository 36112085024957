import { WithStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Theme, withStyles } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';
import React, { Component, ErrorInfo } from 'react';
import config from '../../config/config';

const styles = (theme: Theme) => ({
  errorCol: {
    justifyContent: 'center',
    paddingTop: '5em',
    '& h3': {
      textAlign: 'center',
    },
  },

  btnError: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  iconError: {
    fontSize: '9em',
    color: 'red',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1rem',
  },
  btnReturn: {
    border: '1px solid #FAF0E6!important',
    color: '#F36A00!important',
    backgroundColor: '#FCF7F2!important',
    borderRadius: '11px!important',
    fontSize: '16px!important',
    marginRight: '1.5rem!important',
    paddingLeft: '1rem!important',
    paddingRight: '1rem!important',
  },
  btnNext: {
    border: '1px solid #FF8201!important',
    color: '#FFF!important',
    backgroundColor: '#FF8201!important',
    borderRadius: '11px!important',
    fontSize: '16px!important',
    paddingLeft: '1.5rem!important',
    paddingRight: '1.5rem!important',
    [theme.breakpoints.down('xs')]: {
      padding: '0 !important',
    },
  },
  btnLabel: {
    textTransform: 'none',
    fontFamily: 'IBM Plex Sans Condensed, sans-serif',
    '& span': {
      marginBottom: 3,
    },
  },
});

interface ErrorBoundaryProps {
  children: JSX.Element;
}

interface ErrorBoundaryState {
  eventId?: string;
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps & WithStyles<any>, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps & WithStyles<any>) {
    super(props);
    this.state = { eventId: undefined, hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (config.nodeENV !== 'local') {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId, hasError: true });
      });
    }
  }

  reloadPage = () => {
    window.location.href = config.myURI!;
  };

  render() {
    const { hasError, eventId } = this.state;
    const { children, classes } = this.props;
    if (hasError) {
      return (
        <Grid container={true} className={classes.errorCol}>
          <Grid item={true} lg={6} md={8} sm={10} xs={12}>
            <div className={classes.iconError}>
              <i className="fa fa-exclamation-triangle" aria-hidden="true" />
            </div>
            <h3>Digidom est vraiment désolé pour ce désagrément.</h3>
            <div className={classes.btnError}>
              <Button
                variant="outlined"
                classes={{
                  outlined: classes.btnReturn,
                  label: classes.btnLabel,
                }}
                onClick={this.reloadPage}
              >
                Retour à la page d'accueil
              </Button>
              <Button
                classes={{ outlined: classes.btnNext, label: classes.btnLabel }}
                variant="outlined"
                onClick={() => Sentry.showReportDialog({ eventId })}
              >
                Reporter l'erreur
              </Button>
            </div>
          </Grid>
        </Grid>
      );
    }

    return children;
  }
}

export default withStyles(styles as any)(ErrorBoundary);
