export const getPriceWithTax = (price: number) => {
  if (!price || price === 0) {
    return 0;
  }
  const tax = (price * 20) / 100;
  return price + tax;
};

export const getTax = (price: number) => Math.round(((price * 20) / 100) * 100) / 100;

export const checkLimitFileSize = (file: File) => {
  if (file.size > 5000000) {
    return false;
  }
  return true;
};

export const formatId = (stringId: string) => {
  if (!stringId) {
    return '';
  }
  const split = `${stringId}`.split('/');
  return split[split.length - 1];
};

export const unFormatId = (id: number, typeName: string) => {
  return `/v1/${typeName}/${id}`;
};

export const getPriceWithDiscount = (price: number, percent: number) => {
  const discount = (price * percent) / 100;
  return price - discount;
};

export const getToLowerCaseString = (value?: string | null) =>
  value ? value.trim().toLowerCase() : '';

export const toString = (value?: any | null) => (value ? value.toString() : '');

export const toFixedNumber = (numberToFixed: number, fractionDigits: number) => {
  const digits = fractionDigits || 0;
  const fractionDgts = Math.pow(10, digits);
  const returndeNumber = ((numberToFixed * fractionDgts) / fractionDgts).toFixed(fractionDigits);
  return returndeNumber;
};
