import { createBrowserHistory } from 'history';
import { tunnelAnalytics } from '../App';
import { actionsList } from '../Components/Home/Body/MyActions/MyActionLogic';
import { categoryName, ERouteOption } from '../Interface/Dashboard/CategoryInterface';

const customHistory = createBrowserHistory();
customHistory.listen((location, action) => {
  const [navigation, detail] = location.pathname.split('/').slice(1);

  tunnelAnalytics.trackVu({
    categorie: `${navigation ? categoryName[navigation as ERouteOption] : ''}`,
    titre:
      detail && actionsList.find(a => a.action === detail)
        ? actionsList.find(a => a.action === detail)!.title
        : '',
  });
});

export default customHistory;
