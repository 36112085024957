import React, { FC, useState } from 'react';
import { RefetchContext } from './RefetchContext';

const RefetchContextProvider: FC = ({ children }) => {
  const [refetch, setRefetch] = useState(false);

  const handleRefetch = (arg: boolean) => {
    setRefetch(arg);
  };

  return (
    <RefetchContext.Provider value={{ refetch, setRefetch: handleRefetch }}>
      {children}
    </RefetchContext.Provider>
  );
};

export default RefetchContextProvider;
