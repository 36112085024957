import { ERROR_INFO } from '../../Interface/Dashboard/ErrorInfo';
import isAdmin from '../../Services/isAdmin';

export default {
  snackBar: {
    message: null,
    isOpen: false,
    duration: 5000,
    type: 'SUCCESS',
    __typename: 'SnackBar',
  },

  category: {
    option: 'LETTER_BOX', // MY_DOCUMENTS, MY_ACTIONS, MY_ACCOUNT, NEW_ORGANIZATION
    isLetterImagePaid: false,
    isOpenMenu: false,
    secondOption: null,
    __typename: 'Category',
  },

  selectedOrganization: {
    id: null,
    legalName: null,
    missingDocuments: 0,
    status: null,
    terminationReason: null,
    terminationDate: null,
    invoiceToken: null,
    __typename: 'SelectedOrganization',
  },

  configurationGuide: {
    isOpen: !isAdmin(),
    __typename: 'ConfigurationGuide',
  },

  dialogManyOrg: {
    isOpen: false,
    __typename: 'DialogManyOrg',
  },

  hostFields: {
    lrid: 0,
    firstName: '',
    lastName: '',
    streetAddress: '',
    addressLocality: '',
    postalCode: '',
    country: '',
    addressRegion: '',
    addressCountry: '',
    countryRestriction: '',
    bornDate: new Date(),
    bornPlace: '',
    beginDate: new Date(),
    __typename: 'HostFields',
  },

  informHostFields: {
    isInform: false,
    __typename: 'InformHostFields',
  },

  errorInfo: {
    isOpen: false,
    resolved: true,
    typeOpen: ERROR_INFO.ERROR_ONE,
    __typename: 'ErrorInfo',
  },
};
