import gql from 'graphql-tag';

const typeDefs = gql`
  #enums
  enum ERROR_INFO {
    ERROR_ONE
    ERROR_DOUBLE
  }

  type SnackBar {
    message: String
    type: String
    duration: Int
    isOpen: Boolean
  }

  # Dashboard

  type Category {
    option: String
    isLetterImagePaid: Boolean
    isOpenMenu: Boolean
    secondOption: String
  }

  type SelectedOrganization {
    id: Int
    legalName: String
    missingDocuments: Int
    status: String
    terminationReason: String
    terminationDate: String
    invoiceToken: String
  }

  type ConfigurationGuide {
    isOpen: Boolean
  }

  type DialogManyOrg {
    isOpen: Boolean
  }

  type ErrorInfo {
    isOpen: Boolean
    resolved: Boolean
    typeOpen: ERROR_INFO
  }
  # Tunnel

  type LegalInformation {
    legalName: String
    legalStatus: String
    id: String
  }

  type MyIdentity {
    type: String
  }

  type MyOrganization {
    isLegalRepresentative2: Boolean
    type: String
  }

  type HostFields {
    lrid: Int
    firstName: String
    lastName: String
    streetAddress: String
    addressLocality: String
    postalCode: String
    country: String
    addressRegion: String
    addressCountry: String
    countryRestriction: String
    bornDate: String
    bornPlace: String
    beginDate: String
  }

  type InformHostFields {
    isInform: Boolean
  }

  type TunnelData {
    creationtype: String
    addressId: String
    addressIdFromUrl: String
    letterType: String
    packDomName: String
    packDomNameFromUrl: String
    packImmatName: String
    packImmatNameFromUrl: String
    legalStatus: String
    legalStatusFromUrl: String
    enter: String
    historyNavTunnel: String
    organization: String
    parentId: String
    projectStage: String
    headQuartersAddressType: String
    haveAccountant: Boolean
    isDigidomAccountant: Boolean
    bankChoice: String
    bankName: String
    activityDomain: String
    isCrossSelling: Boolean
  }

  input TunnelInputData {
    creationtype: String
    addressId: String
    addressIdFromUrl: String
    letterType: String
    packDomName: String
    packDomNameFromUrl: String
    packImmatName: String
    packImmatNameFromUrl: String
    legalStatus: String
    legalStatusFromUrl: String
    enter: String
    historyNavTunnel: String
    organization: String
    parentId: String
    projectStage: String
    headQuartersAddressType: String
    haveAccountant: Boolean
    isDigidomAccountant: Boolean
    bankChoice: String
    bankName: String
    activityDomain: String
    isCrossSelling: Boolean
  }
`;

export default typeDefs;
