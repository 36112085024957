import { DataProxy } from 'apollo-cache';
import { GET_TUNNEL_DATA } from '../../graphql/local/Tunnel/query';
import { ITunnelData, StoreDataService } from '../../Services/StoreDataTunnel';
import { debug } from 'console';
import { defauldData } from '../defaultValues';

export default {
  legalInformation: (
    _: any,
    variables: {
      legalName: string;
      legalStatus: string;
      id: string;
    },
    { cache }: { cache: DataProxy },
  ) => {
    const { legalName, legalStatus, id } = variables;
    const data = {
      legalInformation: {
        legalName,
        legalStatus,
        id,
        __typename: 'LegalInformation',
      },
    };
    cache.writeData({ data });
    return null;
  },
  myIdentity: (
    _: any,
    variables: {
      type: string;
    },
    { cache }: { cache: DataProxy },
  ) => {
    const { type } = variables;
    const data = {
      myIdentity: {
        type,
        __typename: 'MyIdentity',
      },
    };
    cache.writeData({ data });
    return null;
  },
  myOrganization: (
    _: any,
    variables: {
      isLegalRepresentative2: boolean;
      type: string;
    },
    { cache }: { cache: DataProxy },
  ) => {
    const { isLegalRepresentative2, type } = variables;
    const data = {
      myOrganization: {
        isLegalRepresentative2,
        type: type || null,
        __typename: 'MyOrganization',
      },
    };
    cache.writeData({ data });
    return null;
  },
  tunnelData: (
    _: any,
    variables: { tunnelInput: ITunnelData },
    { cache }: { cache: DataProxy },
  ) => {
    let incommingData = {
      ...variables.tunnelInput,
      __typename: 'TunnelData',
    };

    const dataLocal = cache.readQuery<{
      tunnelData: ITunnelData;
    }>({
      query: GET_TUNNEL_DATA,
    });

    if (dataLocal && dataLocal.tunnelData) {
      incommingData = {
        ...dataLocal.tunnelData,
        ...incommingData,
      };
    }

    const data = {
      tunnelData: {
        ...incommingData,
      },
    };

    StoreDataService.setDataTunnel(incommingData);
    cache.writeData({ data });
    return data;
  },
  cleartunnelData: (_: any, __: any, { cache }: { cache: DataProxy }) => {
    StoreDataService.removeDataTunnel();
    cache.writeData({ data: defauldData });
    return defauldData;
  },
};
