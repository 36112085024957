import gql from 'graphql-tag';

export const GET_HOST_FIELSD_BY_LR_ID = gql`
  query GET_HOSTFIELD_BY_LR_ID($lrid: Int) {
    hostFields(lrid: $lrid) @client {
      lrid
      firstName
      lastName
      streetAddress
      addressLocality
      postalCode
      country
      addressRegion
      addressCountry
      countryRestriction
      bornDate
      bornPlace
      beginDate
    }
  }
`;

export const GET_ISINFORMHOSTFIELDS = gql`
  {
    informHostFields @client {
      isInform
    }
  }
`;
