import gql from 'graphql-tag';

export const GET_SNACKBAR_STATE = gql`
  {
    snackBar @client {
      type
      message
      isOpen
      duration
    }
  }
`;
