import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import config from './config/config';
import './index.css';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: config.sentryDNS,
  environment: config.nodeENV,
  release: config.release,
  debug: true,
  integrations: [new Integrations.BrowserTracing()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // error safe to be ignored
    'Unexpected token',
    'Loading chunk',
    'Could not load "util"',
    'Failed to load Stripe',
    'SecurityError:',
    'Certaines factures ont déjà été payées',
    'expected expression, got',
    'Failed to fetch',
    'Illegal invocation',
    'NetworkError when attempting to fetch resource',
    'Invalid phone',
    'Client non enregistré',
    '__inspq.filter is not a function',
    'Le ficher doit être inférieur à 5 Mo',
    'GraphQL error: Certaines factures',
    'Non-Error promise rejection captured with value',
    'Unexpected token I in JSON at position 0',
    'GraphQL error: Unauthorized',
    'Error writing result to store for query',
    'Non-Error exception captured with keys',
    'annulé',
    'Load failed',
    "(reading 'pack')",
    'The request timed out.',
    'Should not already be working.',
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    /http:\/\/localhost/i,
  ],
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
