// utils/encryption.ts
import crypto from 'crypto';
import config from '../config/config';

const encryptionData = () => {
  const algorithm = 'aes-256-cbc';
  const keyString = (config.tunnelEncryptionKey || '').slice(0, 32); // Utiliser une clé fixe pour décryptage ultérieur si nécessaire
  const key = Buffer.from(keyString);
  const iv = crypto.randomBytes(16);

  return { algorithm, key, iv };
};

export const encryptText = (
  text: string,
): {
  iv: string;
  encryptedData: string;
} => {
  const { algorithm, key, iv } = encryptionData();

  const cipher = crypto.createCipheriv(algorithm, key, iv);
  let encrypted = cipher.update(text, 'utf8', 'hex');
  encrypted += cipher.final('hex');
  return { iv: iv.toString('hex'), encryptedData: encrypted };
};
