import createMuiTheme from '@material-ui/core/styles/createTheme';

const colors = {
  white: '#FFFFFF',
  grisInput: '#888076',
  pink: '#FFF0DE',
  grisSemiClair: '#EEE9E0',
  marronSemiClair: '#7C7570',
  bleuClairTunnel: '#E5F5FF',
  vertDigidom: '#0eb50e',
  vert: '#00C397',
  orange: '#FFB62D',
  gris: '#777A8E',
  violet: '#3C345A',
  vertUnPeuClaire: '#00C794',
  bleuClaire: '#E1F1F7',
  grisTunnel: '#777A90',
  red: '#ff0000',
  bleu: '#8adbf9',
};

export const themeBV = createMuiTheme({
  palette: {
    primary: {
      main: '#FF9D00',
    },
    secondary: { main: '#FF9D00', light: '#FBB131' },
  },
  colors,
  overrides: {
    MuiInputLabel: {
      root: {
        '&$focused': {
          color: '#FF9D00',
        },
      },
    },
  },
});

export const themeTunnel = createMuiTheme({
  palette: {
    primary: {
      main: '#E1F1F7',
    },
    secondary: { main: '#E1F1F7' },
  },
  colors,
});

export const previewDocThemeBV = createMuiTheme({
  palette: {
    primary: {
      main: '#FF9D00',
    },
    secondary: { main: '#ffe4c4' },
  },
  colors,
});

export const previewDocThemeTunnel = createMuiTheme({
  palette: {
    primary: {
      main: '#00C397',
    },
    secondary: { main: '#E5F5FF' },
  },
  colors: {
    ...colors,
    vert: '#00C397',
    bleuClaire: '#8adbf9',
  },
  // #8adbf9
});

export const prescriberTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#25aafb',
      light: '#43bbfd',
    },
    secondary: { main: '#dbf1fe', light: '#43bbfd', dark: '#fb8200' },
    background: { default: '#f0fafe', paper: 'white' },
    text: { primary: '#43bbfd', secondary: '#74706c' },
  },
  colors,
});
