import { DataProxy } from 'apollo-cache';
import { GET_HOST_FIELSD_BY_LR_ID } from '../../graphql/local/HostFields/query';
import { ERROR_INFO } from '../../Interface/Dashboard/ErrorInfo';

export default {
  snackBar: (
    _: any,
    variables: {
      message?: string;
      type?: 'ERROR' | 'SUCCESS';
      isOpen: boolean;
      duration?: number;
    },
    { cache }: { cache: DataProxy },
  ) => {
    const { message, type, duration, isOpen } = variables;
    const data = {
      snackBar: {
        type: type || 'SUCCESS',
        message: message || null,
        isOpen,
        duration: duration || 5000,
        __typename: 'SnackBar',
      },
    };
    cache.writeData({ data });
    return null;
  },
  errorInfo: (
    _: any,
    variables: {
      isOpen: boolean;
      resolved: boolean;
      typeOpen: ERROR_INFO;
    },
    { cache }: { cache: DataProxy },
  ) => {
    const { isOpen, typeOpen, resolved } = variables;
    const data = {
      errorInfo: {
        isOpen: isOpen || false,
        resolved: resolved || true,
        typeOpen: typeOpen || ERROR_INFO.ERROR_ONE,
        __typename: 'ErrorInfo',
      },
    };
    cache.writeData({ data });
    return null;
  },

  hostFields: (
    _: any,
    variables: {
      lrid: number;
      firstName?: string;
      lastName?: string;
      streetAddress?: string;
      addressLocality?: string;
      postalCode?: string;
      country?: string;
      addressRegion?: string;
      addressCountry?: string;
      countryRestriction?: string;
      bornDate?: string;
      bornPlace?: string;
      beginDate?: string;
    },
    { cache }: { cache: DataProxy },
  ) => {
    const {
      lrid,
      firstName,
      lastName,
      streetAddress,
      addressLocality,
      postalCode,
      country,
      addressRegion,
      addressCountry,
      countryRestriction,
      bornDate,
      bornPlace,
      beginDate,
    } = variables;
    const data = {
      hostFields: {
        lrid: lrid || 0,
        firstName: firstName || '',
        lastName: lastName || '',
        streetAddress: streetAddress || '',
        addressLocality: addressLocality || '',
        postalCode: postalCode || '',
        country: country || '',
        addressRegion: addressRegion || '',
        addressCountry: addressCountry || '',
        countryRestriction: countryRestriction || '',
        bornDate: bornDate || new Date().toISOString(),
        bornPlace: bornPlace || '',
        beginDate: beginDate || new Date().toISOString(),
        __typename: 'HostFields',
      },
    };
    cache.writeQuery({
      query: GET_HOST_FIELSD_BY_LR_ID,
      variables: {
        lrid,
      },
      data,
    });
    return null;
  },

  informHostFields: (
    _: any,
    variables: {
      isInform: boolean;
    },
    { cache }: { cache: DataProxy },
  ) => {
    const { isInform } = variables;
    const data = {
      informHostFields: {
        isInform,
        __typename: 'InformHostFields',
      },
    };
    cache.writeData({ data });
    return null;
  },

  category: (
    _: any,
    variables: {
      option: string;
      secondOption: string;
      isLetterImagePaid: boolean;
      isOpenMenu: boolean;
    },
    { cache }: { cache: DataProxy },
  ) => {
    const { option, isLetterImagePaid, isOpenMenu, secondOption } = variables;
    const data = {
      category: {
        option,
        isLetterImagePaid,
        secondOption,
        __typename: 'Category',
        isOpenMenu,
      },
    };
    cache.writeData({ data });
    return null;
  },
  selectedOrganization: (
    _: any,
    variables: {
      id: number;
      legalName: string;
      missingDocuments: number;
      status: string;
      terminationReason: string;
      terminationDate: string;
      invoiceToken: string;
    },
    { cache }: { cache: DataProxy },
  ) => {
    const {
      id,
      legalName,
      missingDocuments,
      status,
      terminationReason,
      terminationDate,
      invoiceToken,
    } = variables;
    const data = {
      selectedOrganization: {
        id,
        legalName,
        missingDocuments,
        status,
        terminationReason,
        terminationDate,
        invoiceToken,
        __typename: 'SelectedOrganization',
      },
    };

    cache.writeData({ data });
    return null;
  },
  configurationGuide: (_: any, variables: { isOpen: boolean }, { cache }: { cache: DataProxy }) => {
    const { isOpen } = variables;

    const data = {
      configurationGuide: {
        isOpen,
        __typename: 'ConfigurationGuide',
      },
    };

    cache.writeData({ data });
    return null;
  },
  dialogManyOrg: (_: any, variables: { isOpen: boolean }, { cache }: { cache: DataProxy }) => {
    const { isOpen } = variables;

    const data = {
      dialogManyOrg: {
        isOpen,
        __typename: 'DialogManyOrg',
      },
    };

    cache.writeData({ data });
    return null;
  },
};
