import gql from 'graphql-tag';

export const GET_LEGAL_INFORMATION = gql`
  {
    legalInformation @client {
      legalName
      legalStatus
      id
    }
  }
`;

export const GET_MY_IDENTITY = gql`
  {
    myIdentity @client {
      type
    }
  }
`;
export const GET_MY_ORGANIZATION = gql`
  {
    myOrganization @client {
      isLegalRepresentative2
      type
    }
  }
`;

export const GET_TUNNEL_DATA = gql`
  query getTunnelData {
    tunnelData @client {
      creationtype
      addressId
      addressIdFromUrl
      letterType
      packDomName
      packDomNameFromUrl
      packImmatName
      packImmatNameFromUrl
      legalStatus
      legalStatusFromUrl
      enter
      historyNavTunnel
      organization
      parentId
      projectStage
      headQuartersAddressType
      haveAccountant
      bankChoice
      bankName
      activityDomain
      isDigidomAccountant
      isCrossSelling
    }
  }
`;
