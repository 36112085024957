import { ERouteMyActions } from '../../../../Interface/Dashboard/CategoryInterface';
import { MyActionDetailInterface } from '../../../../Interface/Dashboard/MyAction';

export const actionsList: MyActionDetailInterface[] = [
  {
    title: 'Transfert de siège',
    price: 51,
    action: ERouteMyActions.HEADQUARTER,
    details: [
      'Consultation de dossier',
      'Rédaction/insertion annonce légale',
      'Rédaction liasse M2',
      'Dépôt du dossier complet au Greffe*',
      'Frais légaux inclus (Greffe et annonce légale)',
    ],
  },
  {
    title: 'Changement de dirigeant',
    price: 62,
    action: ERouteMyActions.LEADER,
    details: [
      'Constitution du dossier',
      'Rédaction/insertion annonce légale',
      'Rédaction liasse M2',
      'Dépôt du dossier complet au Greffe du Tribunal de Commerce',
      'Frais légaux inclus (Greffe et annonce légale)',
    ],
  },
  {
    title: 'Cession de parts sociales',
    price: 35,
    action: ERouteMyActions.SHARES,
    details: [
      'Constitution du dossier',
      'Rédaction/insertion annonce légale',
      'Rédaction liasse M2',
      'Dépôt du dossier complet au Greffe du Tribunal de Commerce',
      'Frais légaux inclus (Greffe et annonce légale)',
    ],
  },
  {
    title: "Acte de PV d'assemblée",
    price: 62,
    action: ERouteMyActions.PV_DEED,
    details: [
      'Constitution du dossier',
      'Rédaction/insertion annonce légale',
      'Rédaction liasse M2',
      'Dépôt du dossier complet au Greffe du Tribunal de Commerce',
      'Frais légaux inclus (Greffe et annonce légale)',
    ],
  },
  {
    title: 'Liquidation, dissolution, radiation',
    price: 117,
    action: ERouteMyActions.LIQUIDATION,
    details: [
      'Enregistrement du PV de dissolution auprès de la recette des impôts',
      "Rédaction et insertion de l'annonce légale",
      'Rédaction des liasses M2, M4 et de tous les formulaires',
      'Dépôt du dossier complet au Greffe du TC de Paris',
      "Demande d'extrait KBIS de radiation",
      'Frais légaux inclus : Trésor Public*, Annonce légale, Greffe',
    ],
  },
  {
    title: "Changement d'exercice social",
    price: 28,
    action: ERouteMyActions.CHANGE_FISCAL,
    details: [
      'Constitution du dossier',
      'Rédaction/insertion annonce légale',
      'Rédaction liasse M2',
      'Dépôt du dossier complet au Greffe du Tribunal de Commerce',
      'Frais légaux inclus (Greffe et annonce légale)',
    ],
  },
  {
    title: 'Transformation de la forme juridique',
    price: 112,
    action: ERouteMyActions.LEGAL_FORM,
    details: [
      'Enregistrement du PV de nomination du Commissaire à la transformation*',
      "Dépôt auprès du Greffe du Tribunal de Commerce, du PV d'AGE de nomination du Commissaire à la transformation et du rapport établi par le Commissaire à la transformation",
      'Constitution du dossier',
      "Rédaction et insertion de l'annonce légale",
      'Rédaction des liasses M2',
      'Dépôt du dossier complet auprès du Greffe du Tribunal de Commerce',
      'Frais légaux inclus (Trésor Public, Greffe et annonce légale)',
    ],
  },
  {
    title: 'Modification du capital social',
    price: 108,
    action: ERouteMyActions.SHARE_CAPITAL,
    details: [
      'Constitution du dossier',
      'Rédaction/insertion annonce légale',
      'Rédaction liasse M2',
      'Dépôt du dossier complet au Greffe du Tribunal de Commerce',
      'Frais légaux inclus (Greffe et annonce légale)',
    ],
  },
  {
    title: 'Changement de commissaire aux comptes',
    price: 108,
    action: ERouteMyActions.AUDITOR,
    details: [
      "Enregistrement du PV constatant l'augmentation de capital auprès de la recette des impôts",
      'Constitution du dossier',
      'Rédaction/insertion annonce légale',
      'Rédaction des liasses M2',
      'Dépôt du dossier complet au Greffe du Tribunal de Commerce',
      "Demande d'extrait KBIS",
      'Frais légaux inclus : Trésor Public - Annonce légale - Greffe du Tribunal de Commerce',
    ],
  },
  {
    title: "Modification ou adjonction de l'activité",
    price: 62,
    action: ERouteMyActions.ACTIVITY,
    details: [
      "Enregistrement du PV constatant l'augmentation de capital auprès de la recette des impôts",
      'Constitution du dossier',
      'Rédaction/insertion annonce légale',
      'Rédaction des liasses M2',
      'Dépôt du dossier complet au Greffe du Tribunal de Commerce',
      "Demande d'extrait KBIS",
      'Frais légaux inclus : Trésor Public - Annonce légale - Greffe du Tribunal de Commerce',
    ],
  },
  {
    title: 'Autre formalité',
    price: undefined,
    action: ERouteMyActions.OTHER_FORMALITY,
    details: ['Prix sur-mesure'],
  },
];

export const getActionObject = (routeAction: ERouteMyActions): MyActionDetailInterface => {
  return actionsList.filter((a: MyActionDetailInterface) => a.action === routeAction)[0];
};
