const config = {
  serverUri: process.env.REACT_APP_API_URL,
  myURI: process.env.REACT_APP_MY_DOMAINE,
  apiSymphonyDigidom: process.env.REACT_APP_API_DIGIDOM,
  stripePublicAPIKey: process.env.REACT_APP_STRIPE_PUBLISHABLE,
  segmentWriteKey: process.env.REACT_APP_SEGEMENT_WRITE_KEY,
  urlTrackingLetter: process.env.REACT_APP_TRACKING_LETTER,
  digidomSite: process.env.REACT_APP_DIGIDOM_SITE,
  apiBaseURL: process.env.REACT_APP_BASE_URL_API,
  adminUrl: process.env.REACT_APP_NEW_ADMIN_URL,
  cryptoSecretKey: process.env.REACT_APP_CRYPTO_SECRET_KEY,
  crispWebSiteId: process.env.REACT_APP_CRISP_WEBSITE_ID,
  sentryDNS: process.env.REACT_APP_SENTRY_DNS,
  nodeENV: process.env.REACT_APP_ENV,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  corsDigidom: `${process.env.REACT_APP_API_BASE_URL}/proxy`,
  invoiceLink: process.env.REACT_APP_INVOICE_LINK,
  googleTagManager: process.env.REACT_APP_GTM,
  tunnelEncryptionKey: process.env.REACT_APP_TUNNEL_ECRYPTION_KEY,
  tunnelUrl: process.env.REACT_APP_TUNNEL_URL,
};

export default config;
