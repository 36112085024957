import { User } from '../Utils/user';
import { RefreshToken } from './RefreshToken';

export class RefreshTokenAndUser {
  static getTRefreshTokenAndUser = () => {
    return {
      refreshToken: RefreshToken.getTRefreshToken(),
      user: User.getUser(),
      switchUser: User.getSwitchUser(),
    };
  };
}
