import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { FC } from 'react';
import LogoDigidom from '../../../Assets/Images/logo.png';

import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    top: 'calc( 50vh - 110px )',
    left: 'calc( 50vw - 110px )',
    position: 'fixed',
    zIndex: 30,
    width: 220,
    height: 220,
  },
  imageLogo: {
    position: 'absolute',
    width: 200,
    marginTop: -6,
    animationName: 'allAnimation',
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
  },
  divChild: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  circleProgress: {
    position: 'absolute',
  },
}));

const LoadingContent: FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.divChild}>
        <CircularProgress
          className={classes.circleProgress}
          thickness={0.6}
          size={220}
          color="primary"
        />
        <img className={classes.imageLogo} src={LogoDigidom} alt="" />
      </div>
    </div>
  );
};

export default LoadingContent;
