import { ITunnelData } from '../Services/StoreDataTunnel';

export const defauldData: ITunnelData = {
  creationtype: '',
  addressId: '',
  addressIdFromUrl: '',
  letterType: '',
  packDomName: '',
  packDomNameFromUrl: '',
  packImmatName: '',
  packImmatNameFromUrl: '',
  legalStatus: null,
  legalStatusFromUrl: '',
  enter: '',
  historyNavTunnel: '',
  organization: '',
  parentId: '',
  projectStage: null,
  headQuartersAddressType: null,
  haveAccountant: null,
  bankChoice: null,
  bankName: null,
  activityDomain: null,
  isDigidomAccountant: null,
  isCrossSelling: null,
};
