export const getUtmParam = (key: string, defaultQuery: any): string => {
  if (defaultQuery[key]) {
    return defaultQuery[key];
  }
  if (localStorage.getItem(key) !== null) {
    return `${localStorage.getItem(key)}`;
  }
  return '';
};

export const isNotANumber = (input: string): boolean => {
  const regex = /[a-zA-Z]/gm;
  return Boolean(regex.exec(input));
};
