import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: '#ebe9e1',
    display: 'flex',
    justifyContent: 'center',
    padding: '2em',
    minWidth: 600,
    textAlign: 'justify',
    textJustify: 'inter-word',
    [theme.breakpoints.down('sm')]: {
      padding: 'unset',
    },
  },
  cardStyle: {
    padding: '2em',
    width: '75%',
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '1em',
      width: '90%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));
