import React from 'react';

export interface IShowZooContext {
  showZoo: boolean;
  setShowZoo: (show: boolean) => void;
}

export const ShowZooContext = React.createContext<Partial<IShowZooContext>>({});

export const useShowZooContext = () => React.useContext(ShowZooContext);
