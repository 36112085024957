import parse from 'url-parse';
import {
  EPackNameSegment,
  OrganizationStatusImmatEnum,
  OrganizationStatusTransferEnum,
} from '../../../Interface/Dashboard/OrganizationInterface';
import { TrackSegmentInput } from '../../../Interface/SegmentInterface';
import { UserInterface } from '../../../Interface/UserInterface';
import { getAnonymousId, track } from '../analytics';
import { getUtmParam } from '../utils';
import { segmentTrack, segmentIdentifyScanEnveloppe } from '../../../Services/RestFull';
import { categoryName, ERouteOption } from '../../../Interface/Dashboard/CategoryInterface';
import { actionsList } from '../../../Components/Home/Body/MyActions/MyActionLogic';
import { User } from '../../../Utils/user';

export default class TunnelAnalytics {
  isFirst = true;

  userId: string = '';

  userAdmin: UserInterface | null = null;

  init = (user: UserInterface) => {
    if (user.countActiveOrganizations) {
      this.isFirst = false;
    }
    this.userId = user.uniqId || user.id;
  };

  initUserAdmin = (userAdmin: UserInterface) => {
    this.userAdmin = userAdmin;
  };

  trackServerSide = async (inputData: TrackSegmentInput, isUtm: boolean, integrations?: any) => {
    const referrer = window.location.href;
    const url = parse(referrer, true);

    const utm = isUtm
      ? {
          referral_utm_campaign: getUtmParam('utm_campaign', url),
          referral_utm_content: getUtmParam('utm_content', url),
          referral_utm_medium: getUtmParam('utm_medium', url),
          referral_utm_source: getUtmParam('utm_source', url),
          referral_utm_term: getUtmParam('utm_term', url),
        }
      : {};

    segmentTrack({
      data: {
        ...inputData.data,
        properties: {
          ...inputData.data.properties,
          ...utm,
        },
        integrations,
      },
      email: this.userAdmin ? this.userAdmin.email : undefined,
    });
  };

  identifyServerSideScanEnveloppe = async (organizationId: string) => {
    segmentIdentifyScanEnveloppe({
      organizationId,
      email: this.userAdmin ? this.userAdmin.email : undefined,
    });
  };

  trackTunnelUserAndCompany = async (
    propertiesUser: any,
    propertiesCompany: any,
    organizationUniqId: string,
    isTrackUser: boolean,
  ) => {
    const referrer = window.location.href;
    const url = parse(referrer, true);

    if (this.isFirst && isTrackUser) {
      segmentTrack({
        data: {
          userId: this.userId,
          event: 'Tunnel',
          properties: {
            ...propertiesUser,
            referral_utm_campaign: getUtmParam('utm_campaign', url),
            referral_utm_content: getUtmParam('utm_content', url),
            referral_utm_medium: getUtmParam('utm_medium', url),
            referral_utm_source: getUtmParam('utm_source', url),
            referral_utm_term: getUtmParam('utm_term', url),
            idType: 'user',
          },
          integrations: { All: true, 'Google Analytics': true, 'Google Analytics 4': true },
          anonymousId: getAnonymousId(),
        },
        email: this.userAdmin ? this.userAdmin.email : undefined,
      });
    }

    segmentTrack({
      data: {
        userId: organizationUniqId,
        event: 'Tunnel',
        properties: {
          ...propertiesCompany,
          referral_utm_campaign: getUtmParam('utm_campaign', url),
          referral_utm_content: getUtmParam('utm_content', url),
          referral_utm_medium: getUtmParam('utm_medium', url),
          referral_utm_source: getUtmParam('utm_source', url),
          referral_utm_term: getUtmParam('utm_term', url),
          idType: 'company',
          userId: organizationUniqId,
        },
        integrations: { All: true, 'Google Analytics': true, 'Google Analytics 4': true },
      },
      email: this.userAdmin ? this.userAdmin.email : undefined,
    });
  };

  trackTunnelServer = async (propertiesCompany: any, organizationUniqId: string) => {
    const referrer = window.location.href;
    const url = parse(referrer, true);

    segmentTrack({
      data: {
        userId: organizationUniqId,
        event: 'Tunnel',
        properties: {
          referral_utm_campaign: getUtmParam('utm_campaign', url),
          referral_utm_content: getUtmParam('utm_content', url),
          referral_utm_medium: getUtmParam('utm_medium', url),
          referral_utm_source: getUtmParam('utm_source', url),
          referral_utm_term: getUtmParam('utm_term', url),
          idType: 'company',
          userId: organizationUniqId,
          ...propertiesCompany,
        },
        integrations: { All: true, 'Google Analytics': true, 'Google Analytics 4': true },
      },
      email: this.userAdmin ? this.userAdmin.email : undefined,
    });
  };

  trackTunnelCustom = async (organizationUniqId: string, propertiesCompany: any) => {
    const referrer = window.location.href;
    const url = parse(referrer, true);

    segmentTrack({
      data: {
        userId: organizationUniqId,
        event: 'Tunnel',
        properties: {
          referral_utm_campaign: getUtmParam('utm_campaign', url),
          referral_utm_content: getUtmParam('utm_content', url),
          referral_utm_medium: getUtmParam('utm_medium', url),
          referral_utm_source: getUtmParam('utm_source', url),
          referral_utm_term: getUtmParam('utm_term', url),
          idType: 'company',
          userId: organizationUniqId,
          ...propertiesCompany,
        },
        integrations: { All: true, 'Google Analytics': true, 'Google Analytics 4': true },
      },
      email: this.userAdmin ? this.userAdmin.email : undefined,
    });
  };

  trackTunnel = (properties: any) => {
    const referrer = window.location.href;
    const url = parse(referrer, true);
    const idType = properties.idType ? properties.idType : 'company';

    track(
      'Tunnel',
      {
        ...properties,
        referral_utm_campaign: getUtmParam('utm_campaign', url),
        referral_utm_content: getUtmParam('utm_content', url),
        referral_utm_medium: getUtmParam('utm_medium', url),
        referral_utm_source: getUtmParam('utm_source', url),
        referral_utm_term: getUtmParam('utm_term', url),
        idType,
      },
      {
        integrations: {
          AdWords: true,
          All: true,
          'Bing Ads': true,
          'Facebook Pixel': true,
          'Google Analytics': true,
          'Google Analytics 4': true,
        },
      },
    );
  };

  trackStatusClientTransferOrCreate = (properties: {
    statut_immat: OrganizationStatusImmatEnum.NEW_PAYMENT | '';
    statut_transfert: OrganizationStatusTransferEnum.NEW_PAYMENT | '';
  }) => {
    track('Statut Client', {
      ...properties,
      agent: this.userAdmin ? this.userAdmin.email : '',
    });
  };

  trackOrderCompleted = (properties: any) => {
    const referrer = window.location.href;
    const url = parse(referrer, true);
    track(
      'Order Completed',
      {
        ...properties,
        referral_utm_campaign: getUtmParam('utm_campaign', url),
        referral_utm_content: getUtmParam('utm_content', url),
        referral_utm_medium: getUtmParam('utm_medium', url),
        referral_utm_source: getUtmParam('utm_source', url),
        referral_utm_term: getUtmParam('utm_term', url),
      },
      {
        integrations: {
          AdWords: true,
          All: true,
          'Bing Ads': true,
          'Facebook Pixel': true,
          'Google Analytics': true,
          'Google Analytics 4': true,
        },
      },
    );
  };

  trackVu = (props?: { categorie: string; titre: string; idType?: string; userId?: string }) => {
    const user = User.user;

    let categorie = '';
    let titre = '';
    let idType = 'user';
    let userId = user && user.uniqId;
    if (props) {
      categorie = props.categorie;
      titre = props.titre;
      idType = props.idType ? props.idType : 'user';
      userId = props.userId ? props.userId : user && user.uniqId;
    } else {
      const [navigation, detail] = window.location.pathname.split('/').slice(1);
      categorie = `${
        navigation
          ? categoryName[navigation as ERouteOption]
            ? categoryName[navigation as ERouteOption]
            : navigation
          : ''
      }`;
      titre =
        detail && actionsList.find(a => a.action === detail)
          ? actionsList.find(a => a.action === detail)!.title
          : '';
    }
    const currentUrl = parse(window.location.href, true);

    const properties = {
      category: 'Bureau Virtuel',
      categorie,
      nonInteraction: 1,
      titre,
      referral_utm_campaign: getUtmParam('utm_campaign', currentUrl),
      referral_utm_content: getUtmParam('utm_content', currentUrl),
      referral_utm_medium: getUtmParam('utm_medium', currentUrl),
      referral_utm_source: getUtmParam('utm_source', currentUrl),
      referral_utm_term: getUtmParam('utm_term', currentUrl),
      agent: this.userAdmin ? this.userAdmin.email : '',
      idType,
      userId,
    };

    track('Vu', properties, {
      integrations: {
        All: true,
        'Facebook Pixel': false,
      },
    });
  };
}
