export default {
  clearToken: () => localStorage.setItem('access_token', ''),
  setAccessToken: (token: string) => localStorage.setItem('access_token', token),
  getAccessToken: (): string | null => localStorage.getItem('access_token'),
  setRefreshToken: (token: string) => localStorage.setItem('refresh-token', token),
  getRefreshToken: (): string | null => localStorage.getItem('refresh-token'),
  removRefreshToken: () => localStorage.removeItem('refresh-token'),
  getUserId: (): string | null => localStorage.getItem('user_id'),
  setUserId: (userId: string) => localStorage.setItem('user_id', userId),
  getSwitchUser: (): string | null => localStorage.getItem('switch_user'),
  setSwitchUser: (switch_user: string) => localStorage.setItem('switch_user', switch_user),
  getAdminLink: (): string | null => localStorage.getItem('admin_link'),
  setAdminLink: (switch_user: string) => localStorage.setItem('admin_link', switch_user),
  getSwitchUserId: (): string | null => localStorage.getItem('switch_user_id'),
  setSwitchUserId: (userId: string) => localStorage.setItem('switch_user_id', userId),
  getOrganizationIDByAdmin: (): string | null => localStorage.getItem('org-id-admin'),
  setOrganizationIDByAdmin: (organizationId: string) =>
    localStorage.setItem('org-id-admin', organizationId),
  setIsEditOrganization: (edit: string) => localStorage.setItem('is-edit-organization', edit),
  getIsEditOrganization: (): string | null => localStorage.getItem('is-edit-organization'),
  setToPage: (to: string) => localStorage.setItem('to_page', to),
  getToPage: (): string | null => localStorage.getItem('to_page'),
  setIsPrescriber: (isPrescriber: string) => localStorage.setItem('is_prescriber', isPrescriber),
  getIsPrescriber: (): string | null => localStorage.getItem('is_prescriber'),
  setOrganizationIDByPrescriber: (organizationId: string) =>
    localStorage.setItem('org-from-prescriber', organizationId),
  getOrganizationIDByPrescriber: (): string | null => localStorage.getItem('org-from-prescriber'),
  getPrescriberId: (): string | null => localStorage.getItem('prescriber-id'),
  setPrescriberId: (prescriberId: string) => localStorage.setItem('prescriber-id', prescriberId),
  getNoCrosselling: (): string | null => localStorage.getItem('no-crosselling'),
  setNoCrosselling: (value: string) => localStorage.setItem('no-crosselling', value),
  getIsRegenerateContract: (): string | null => localStorage.getItem('edit-contract'),
  setIsRegenerateContract: (value: string) => localStorage.setItem('edit-contract', value),
  getIsRegenerateContractImmat: (): string | null => localStorage.getItem('edit-contract-immat'),
  setIsRegenerateContractImmat: (value: string) =>
    localStorage.setItem('edit-contract-immat', value),
  getShowBeforePayment: (): string | null => localStorage.getItem('show-before-payment'),
  setShowBeforePayment: (value: string) => localStorage.setItem('show-before-payment', value),
  isFromBV: (): string | null => localStorage.getItem('is-from-bv'),
  setIsFromBV: (value: string) => localStorage.setItem('is-from-bv', value),
  getIsImmatOnly: (): string | null => localStorage.getItem('is-immat-only'),
  setIsImmatOnly: (value: string) => localStorage.setItem('is-immat-only', value),
  getTunnelData: (): string | null => localStorage.getItem('tunnel-data'),
  setTunnelData: (value: string) => localStorage.setItem('tunnel-data', value),
  removTunnelData: () => localStorage.removeItem('tunnel-data'),
  getSkipMyAccountOrg: (): string | null => localStorage.getItem('skip-my-account'),
  setSkipMyAccountOrg: (value: string) => localStorage.setItem('skip-my-account', value),
};
